import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import Features from '../components/Features'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="full-width-image is-fullheight margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `50%`,
        height: `100vh`
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="shibire-font-edge has-text-weight-bold is-size-4-mobile is-size-4-tablet is-size-1-widescreen"
          style={{
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          「175°DENO担担麺」の新ブランド「175°DENOカレー」
        </h1>
        <h3
          className="shibire-font-edge has-text-centered has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          175°の香辛料のノウハウを最大限活かした無水スパイスカレー
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-12 has-text-centered">
              <img src="/img/shibire.png" alt="shibire" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <h1 className="has-text-centered has-text-weight-semibold title is-size-4-mobile">
                175°DENOブランド
              </h1>
              <h3 className="has-text-centered has-text-weight-semibold title is-size-5-mobile">
                痺れる美味しさ。食べるほど美しく、健康に。
              </h3>
            </div>
          </div>
        </div>
        <Features gridItems={intro.blurbs} />
        <div className="columns is-centered">
          <div className="column is-8">
            <a href="https://www.175.co.jp" target="_blank" rel="noreferrer">
              <button className="button is-large is-fullwidth shibire-background-green has-text-white-ter has-text-weight-semibold is-size-6-mobile">
                <span className="mr-1">175°DENO担担麺 についてはこちら</span>
                <FontAwesomeIcon icon={faAngleRight} />
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-12 has-text-centered">
            <img src="/img/l_logo.png" alt="shibire" style={{ width: '700px' }} />
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient shibire-background-green">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="columns">
                <div className="column is-12">
                  <p className="title is-size-5-mobile sub-title has-text-centered has-text-weight-bold has-text-white-ter">
                    最大17種類のスパイスで免疫力向上にも！<br />
                    美味しく食べて健康活動ができるスパイスカレー
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="box" style={{ border: "solid 1px #888888"  }}>
                <div className="columns is-vcentered">
                  <div className="column is-4 has-text-centered">
                    <img src="/img/curry_1.png" alt="image" style={{ width: '300px' }} />
                  </div>
                  <div className="column is-8">
                    <div className="content has-text-weight-semibold">
                      <p>1. 175°DENO 担担麺、渾身の NEW ブランド!「175°DENO カレー」</p>
                      <p>2. 175°の香辛料のノウハウを最大限活かし開発したスパイスカレー</p>
                      <p>3. 美容効果や免疫力向上にも期待ができる、心にもカラダにもうれしいカレー</p>
                      <p>4. 175°の特別な花椒で香り高い美味しさを味わえるシビ辛カレー</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <h3 className="is-size-4-mobile title is-spaced is-2 has-text-centered has-text-weight-semibold">
                175°のチキンカレー
              </h3>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                175°が香辛料のノウハウを最大限活かし、8700時間研究開発。最大 17 種類のスパイスを使ったチキンたっぷりの無水カレーです。
              </p>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                ご自宅では作ることが難しい、スパイスをふんだんに使用し“着色料・甘味料・香料不使用・小⻨粉不使用”でお子様にも安心して食べていただけます。
              </p>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                香り高い175°こだわりの花椒を筆頭に、種類豊富なスパイスの力で、美容効果や免疫力向上にも期待ができる、心にもカラダにもうれしい、健康志向のカレーとなっております。
              </p>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                スパイスのフレッシュ感や、香りを保つ最適な煮込み時間で丁寧に仕上げており、全て手作業でほぐしたチキンは、ジューシーでボリューム感が感じられるとても満足度の高い逸品です。
              </p>
            </div>
            <div className="column is-6">
              <div className="columns is-centered">
                <div className="column is-8">
                  <figure className="image">
                    <img src="/img/poster.png" alt="image" style={{ width: '500px' }} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns is-vcentered is-flex-direction-row-reverse">
            <div className="column is-6">
              <h3 className="is-size-4-mobile title is-spaced is-2 has-text-centered has-text-weight-semibold">
                175°のラムカレー
              </h3>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                パンチの効いた旨み際立つ店主のこだわりが詰まった絶品無水カレー！
              </p>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                ラム肉は、必須アミノ酸・不飽和脂肪酸・ビタミンがとても豊富で、美と健康効果や脂肪燃焼効果も高いとされています。
                ラム肉のガツンとした旨味とコクをより味わっていただけるようにするため、甘味や酸味のバランスを意識しスパイスを組み合わせ、さらにハーブを使用することでより一層175°だけの味わいに仕上げました。
              </p>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-semibold">
                化学調味料・着色料・甘味料、不使用
              </p>
            </div>
            <div className="column is-6">
              <div className="columns is-centered">
                <div className="column is-8">
                  <figure className="image">
                    <img src="/img/poster2.png" alt="image" style={{ width: '500px' }} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="mb-4">
            <h3 className="is-size-4-mobile title is-spaced is-2 has-text-centered has-text-weight-semibold">
              スパイスの効果・効能
            </h3>
            <h4 className="title is-size-5-mobile has-text-centered color-shibire-green">
              最大17種類のスパイスで免疫力向上にも！<br className="is-hidden-mobile" />
              おうちで食べて健康活動ができるスパイスカレー！
            </h4>
          </div>
          <div className="columns">
            <div className="column is-6 has-text-centered">
              <div>
                <img src="/img/spyces.jpg" alt="image" style={{ width: '100%' }} />
              </div>
              <div>
                <p className="is-size-4 is-size-6-mobile has-text-weight-semibold" style={{ color: '#CA934D' }}>
                  種類豊富なスパイスの力で、<br className="is-hidden-mobile" />
                  美容効果や免疫力向上にも期待ができる、<br className="is-hidden-mobile" />
                  心にもカラダにもうれしいカレーです。
                </p>
              </div>
            </div>
            <div className="column is-6">
              <p className="subtitle is-size-6-mobile has-text-weight-semibold has-text-centered mt-3 mb-2">下記、効能が期待できると言われています。</p>
              <table class="table">
                <tbody>
                  <tr>
                    <th>★</th>
                    <td style={{ width: "130px" }}>青花椒</td>
                    <td>精神安定,血圧を下げる,消化器官改善,消炎鎮痛,ホルモンバランスを整える,皮膚表面のばい菌を抑制する</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>クミン</td>
                    <td>整腸作用・抗酸化作用</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>とうがらし</td>
                    <td>新陳代謝活性化・脂肪燃焼効果</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>グローブ</td>
                    <td>抗菌・殺菌・消臭作用</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>黒コショウ</td>
                    <td>免疫力向上、脂肪燃焼効果、血行促進冷え性改善</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>シナモン</td>
                    <td>抗酸化作用アンチエイジング効果</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>カルダモン</td>
                    <td>疲労回復、整腸作用、精神安定、リラックス効果、脳内分泌活性化</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>ターメリック</td>
                    <td>肝機能向上・認知症予防 抗酸化作用、美肌効果</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>コリアンダー</td>
                    <td>食欲増進、血液浄化</td>
                  </tr>
                  <tr>
                    <th>★</th>
                    <td>その他香辛料</td>
                    <td>企業秘密</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns is-vcentered is-flex-direction-row-reverse">
            <div className="column is-6">
              <h3 className="title is-size-4-mobile is-spaced is-2 has-text-centered has-text-weight-bold">
                175°のチキンカレー<br className="is-hidden-desktop"/>のメニュー
              </h3>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-bold">
                ふわふわ感を出し北海道の雪山をイメージしたチーズや175°オリジナルの手仕込みした揚げたて唐揚げ、ラーメン屋ならではのチャーシューや餃子などをトッピング♪

              </p>
              <p className="subtitle is-size-5-mobile is-4 has-text-weight-bold">
                さらにお好みで辛さやシビれを選ぶことができ、175°独特のシビ辛カレーをお楽しみいたただけます。175°の辣油や花椒との相性は抜群で、美味しさを一層引き立ててくれます。
              </p>
            </div>
            <div className="column is-6">
              <div className="columns is-centered">
                <div className="column is-8">
                  <figure className="image">
                    <img src="/img/menu.jpg" alt="image" style={{ width: '500px' }} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="section">
          <img src="/img/review.png" alt="image" style={{ width: '100%' }} />
        </div>
        <div className="section">
          <div className="columns is-centered">
            <div className="column is-8">
              <img src="/img/curry_3.png" alt="image" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h3 className=" is-size-4-mobile is-spaced has-text-centered has-text-weight-semibold title">
                よくあるご質問
              </h3>
            </div>
          </div>
          <div className="columns">
            <div className="column is-10 is-offset-2">
              <div className="content">
                <p className="title is-spaced is-4 has-text-weight-bold is-bold-light">
                  Q. 商品を購入するには？
                </p>
                <div className="columns">
                  <div className="column is-8 is-offset-1">
                    <a href="https://175deno.base.shop" target="_blank" rel="noreferrer">
                      <button className="button is-large is-size-7-mobile is-fullwidth shibire-background-green has-text-white-ter has-text-weight-bold">
                        <span className="mr-1">175°DENOオンラインショップはこちら</span>
                        <FontAwesomeIcon icon={faAngleRight} />
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="content">
                <p className="title is-spaced is-4 has-text-weight-bold is-bold-light">
                  Q. アレルゲン項目に含まれる食品の使用はありますか。
                </p>
                <p className="subtitle has-text-weight-semibold">
                  A. 原材料に鶏肉、大豆を含むものを使用しております。
                </p>
              </div>
              <div className="content">
                <p className="title is-spaced is-4 has-text-weight-bold is-bold-light">
                  Q. 辛さレベルは？辛さ選べるの？
                </p>
                <p className="subtitle has-text-weight-semibold">
                  A. 中辛です。お店では辛さやシビれも選べます。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <h2 className="has-text-centered is-size-4-mobile is-spaced has-text-weight-semibold title">
            自社で175°DENOブランドを取り扱いませんか？
          </h2>
          <div className="columns is-vcentered">
            <div className="column is-offset-2 has-text-centered">
              <img src="/img/logo_both.png" alt="image" style={{ width: '350px' }} />
            </div>
            <div className="column is-offset-1 is-7">
              <div className="mb-6">
                <h3 className="title is-spaced has-text-weight-semibold is-size-4 mb-5">
                  175°DENOブランドを扱いたい！
                </h3>
                <h3 className="title is-spaced has-text-weight-semibold is-size-4 mb-5">
                  175°DENOブランドが好きな方
                </h3>
                <h3 className="title is-spaced has-text-weight-semibold is-size-4">
                  175°DENOブランドで笑顔を広げたい
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <h2 className="has-text-centered is-size-4-mobile is-spaced has-text-weight-semibold title">
            シェアブランド(ゴーストキッチン)・<br />御社メニューとのコラボ商品などをご提案いたします。
          </h2>
          <div className="mb-6 has-text-centered">
            <p className="subtitle is-spaced is-size-5-mobile has-text-weight-semibold is-size-4">
              「お店のー品料理・メニューの一つにしてみたい」
            </p>
            <p className="subtitle is-spaced is-size-5-mobile has-text-weight-semibold is-size-4">
              「人気の辛さや・シビれの商品を作りたい」
            </p>
            <p className="subtitle is-spaced is-size-5-mobile has-text-weight-semibold is-size-4">
              「ロスなく・手間のない調理で本格的な料理をご提供したい」
            </p>
            <p className="subtitle is-spaced is-size-5-mobile has-text-weight-semibold is-size-4">
              「デリバリーを始めてみたい」
            </p>
          </div>
        </div>
        <hr/>
        <div className="section">
          <div className="columns is-centered">
            <h3 className="title is-spaced is-2 has-text-weight-semibold is-size-4">
              175°DENOブランドにご興味がある企業様・飲食店オーナー様は<br className="is-hidden-desktop"/>
              お気軽に電話、メールでお問合せください。
            </h3>
          </div>
          <div className="columns is-centered">
            <div className="column is-6">
              <a href="https://www.175.co.jp/contact" target="_blank" rel="noreferrer">
                <button className="button is-large is-size-5-mobile is-fullwidth shibire-background-green has-text-white-ter has-text-weight-bold">
                  <span className="mr-1">お問い合わせはこちらから</span>
                  <FontAwesomeIcon icon={faAngleRight} />
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 350, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            head
            text
          }
          heading
          description
        }
      }
    }
  }
`
